import React from 'react'
import PropTypes from 'prop-types'
import DropdownStyled from './styledComponent'
import Tooltip from '@material-ui/core/Tooltip'

import DropdownIcon from '../../asset/images/button/dropdown-arrow.svg'
import dropdownOpenIcon from '../../asset/images/button/dropdown-open-arrow.svg'
import ClearIcon from '../../asset/images/button/clear.svg'
import TrashGreenIcon from '../../asset/images/button/trash-green.svg'
import MESSAGE from './message'
import colors from '../../colors'

class Dropdown extends React.PureComponent {
  state = {
    dropdownOpen: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.closeDropdown()
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getLabel() {
    const { label } = this.props
    let optionalLabel = ''
    if (this.props.isOptional) {
      optionalLabel = this.getMessage('optional')
    }
    return (
      <div className="label-wrapper text-style">
        {label}
        <span>{optionalLabel}</span>
      </div>
    )
  }

  renderDropdownIcon() {
    return this.state.dropdownOpen ? dropdownOpenIcon : DropdownIcon
  }

  onDeleteOption(e, option) {
    e.preventDefault()
    e.stopPropagation()
    this.props.onRemoveOption(option)
  }

  renderSelectedList() {
    let output = null
    const { value } = this.props
    if (Array.isArray(value) && value.length > 0) {
      output = value.map((option, id) => (
        <Tooltip title={option.text} key={id}>
          <div className="chip-option" key={option.value}>
            <div className="chip-text text-style prevent-text-overflow">{option.text}</div>
            <div className="chip-clear" onClick={(e) => this.onDeleteOption(e, option)}>
              <img src={ClearIcon} alt="" />
            </div>
          </div>
        </Tooltip>
      ))
    } else {
      output = <div>{'-- Select Project(s) --'}</div>
    }
    return <div className="dropdown multiple">{output}</div>
  }

  renderClearAllOption() {
    let output = null
    const { value } = this.props
    if (Array.isArray(value) && value.length > 0) {
      output = (
        <div className="clear-btn-wrapper">
          <div className="flex" onClick={() => this.props.onClearAll()}>
            <img src={TrashGreenIcon} alt="" />
          </div>
        </div>
      )
    }
    return output
  }

  getDropdownGraphVersion() {
    const selectedValue = this.props.options.find(
      (optionData) => this.props.value && (optionData.value === this.props.value || optionData.value === this.props.value.value)
    )
    let selectedValueText = ''
    if (!selectedValue) {
      selectedValueText = `-- ${this.getMessage('select')} ${this.props.label.charAt(0).toUpperCase() + this.props.label.slice(1).toLowerCase()} --`
    } else {
      selectedValueText = selectedValue.text
    }
    return (
      <div className="dropdown-wrapper for-graph" onClick={() => this.toggleDropdown()}>
        <div className="dropdown text-style">
          <div className={'circle-color'} style={{ background: `${this.props.value.color}`, width: '10px', height: '10px' }} />
          {selectedValueText}
        </div>
        <div className="dropdown-icon-wrapper" style={{ backgroundColor: this.props.disabled ? colors.greyDisabled : this.props.buttonColor }}>
          <img src={this.renderDropdownIcon()} alt="" />
        </div>
      </div>
    )
  }

  getDropdownCommonVersion() {
    const selectedValue = this.props.options.find(
      (optionData) => this.props.value && (optionData.value === this.props.value || optionData.value === this.props.value.value)
    )
    let selectedValueText = ''
    if (!selectedValue) {
      selectedValueText = `-- ${this.getMessage('select')} ${this.props.label.charAt(0).toUpperCase() + this.props.label.slice(1).toLowerCase()} --`
    } else {
      selectedValueText = selectedValue.text
    }
    let dropdownClassName = this.props.disabled ? ' disabled' : ''
    dropdownClassName += this.state.dropdownOpen ? ' wrapper-open' : ''
    return (
      <div className={'dropdown-wrapper' + dropdownClassName} onClick={() => (this.props.disabled ? null : this.toggleDropdown())}>
        <div className="dropdown">
          <div className="dropdown-text text-style prevent-text-overflow">{selectedValueText}</div>
        </div>
        <div className="dropdown-icon-wrapper" style={{ backgroundColor: this.props.disabled ? colors.greyDisabled : this.props.buttonColor }}>
          <img src={this.renderDropdownIcon()} alt="" />
        </div>
      </div>
    )
  }

  getDropdownMultiSelectVersion() {
    let dropdownClassName = this.props.disabled ? ' disabled' : ''
    return (
      <div className={'dropdown-wrapper multiple-select' + dropdownClassName} onClick={() => (this.props.disabled ? null : this.toggleDropdown())}>
        {this.renderSelectedList()}
        {this.renderClearAllOption()}
        <div className="dropdown-icon-wrapper" style={{ backgroundColor: this.props.disabled ? colors.greyDisabled : this.props.buttonColor }}>
          <img src={this.renderDropdownIcon()} alt="" />
        </div>
      </div>
    )
  }

  getDropdown() {
    if (this.props.isForGraph) {
      return this.getDropdownGraphVersion()
    } else if (this.props.multiSelect) {
      return this.getDropdownMultiSelectVersion()
    } else {
      return this.getDropdownCommonVersion()
    }
  }

  onChange(optionData) {
    this.props.onChange && this.props.onChange(optionData)
    this.closeDropdown()
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  closeDropdown() {
    this.setState({ dropdownOpen: false })
  }

  handleMouseOutOptions = (e) => {
    const currentTarget = e.target
    const relatedTarget = e.relatedTarget
    if (!this.props.isForGraph && currentTarget.parentNode !== relatedTarget.parentNode) {
      this.closeDropdown()
    }
  }

  getDropdownOption() {
    let optionClassName = 'option-wrapper'
    if (this.state.dropdownOpen) {
      optionClassName += ' open'
    }
    let options = this.props.options.map((optionData) => {
      return (
        <div key={optionData.text} className="option-child prevent-text-overflow" onClick={() => this.onChange(optionData)}>
          {optionData.text}
        </div>
      )
    })

    if (this.props.isForGraph) {
      optionClassName += ' option-for-graph'
      options = this.props.options.map((optionData) => {
        const selectedOptionClassName = optionData.value === this.props.value.value ? ' selected' : ''
        return (
          <div
            key={optionData.value}
            className={'legend-option' + selectedOptionClassName}
            onClick={() => (optionData.value === this.props.value.value ? null : this.onChange(optionData))}
          >
            <div className="circle-color" style={{ background: `${optionData.color}`, width: '10px', height: '10px' }} />
            <div className="data-legend-value">{optionData.text}</div>
          </div>
        )
      })
    }

    return (
      <div className={optionClassName + ' text-style'} onMouseOut={this.handleMouseOutOptions}>
        {options}
      </div>
    )
  }

  render() {
    return (
      <DropdownStyled id={this.props.id} className={this.props.className + ` ${this.props.labelPosition}`}>
        {this.props.label ? this.getLabel() : null}
        <div className="drop-down-component" ref={(wp) => (this.wrapperRef = wp)}>
          {this.getDropdown()}
          {this.getDropdownOption()}
        </div>
      </DropdownStyled>
    )
  }
}

Dropdown.defaultProps = {
  language: 'EN',
  multiSelect: false,
  buttonColor: colors.jadeGreen
}

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.string,
      color: PropTypes.string
    }),
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
    })
  ]).isRequired,
  isForGraph: PropTypes.bool,
  labelPosition: PropTypes.string,
  buttonColor: PropTypes.string,
  isOptional: PropTypes.bool,
  multiSelect: PropTypes.bool,
  language: PropTypes.string,
  onRemoveOption: PropTypes.func,
  onClearAll: PropTypes.func,
  disabled: PropTypes.bool
}

export default Dropdown
