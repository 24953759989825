const MESSAGE = {
  EN: {
    select: 'Select',
    optional: 'optional'
  },
  TH: {
    select: 'เลือก',
    optional: 'ไม่จำเป็น'
  }
}

export default MESSAGE
