import styled from 'styled-components'

export default styled.div`
  display: flex;
  position: relative;
  &.top {
    display: block;
    .label-wrapper {
      text-align: left;
      margin-bottom: 8px;
      font-family: 'Prompt', sans-serif;
      font-size: 14px;
    }
  }
  &.left {
    .label-wrapper {
      font-family: 'Prompt', sans-serif;
      font-size: 12px;
      span {
        font-family: 'Prompt', sans-serif;
        font-size: 12px;
      }
    }
  }
  .label-wrapper {
    font-size: 12px;
    font-family: 'Prompt', sans-serif;
    text-align: right;
    color: ${(props) => props.theme.darkBackground};
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      color: ${(props) => props.theme.greyDisabled};
      font-style: italic;
      font-size: 12px;
    }
  }
  .drop-down-component {
    width: 100%;
    flex: 1;
    position: relative;
    .dropdown-wrapper {
      display: flex;
      width: 100%;
      min-width: 200px;
      height: 30px;
      border: 1px solid ${(props) => props.theme.borderDropdown};
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      &:hover {
        cursor: pointer;
        border-color: transparent;
        .dropdown {
          border-left-color: transparent;
          border-top-color: transparent;
          border-bottom-color: transparent;
        }
      }
      &.wrapper-open {
        border-color: ${(props) => props.theme.jadeGreen};
      }
      .dropdown {
        width: calc(100% - 30px);
        background: white;
        font-size: 12px;
        padding: 0 10px;
        font-family: 'Prompt', sans-serif;
        color: ${(props) => props.theme.darkBackground};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        display: flex;
        align-items: center;
        .dropdown-text {
          width: 100%;
          font-size: 12px;
        }
        .circle-color {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }
      }
      .dropdown-icon-wrapper {
        width: 30px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50%;
          height: 50%;
          object-fit: contain;
        }
      }
      &.for-graph {
        height: 25px;
        background: #f2f2f2;
        .dropdown {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          background: #f2f2f2;
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          color: #1f2d39;
          opacity: 0.5;
          font-weight: normal;
          line-height: 0.56;
          padding-left: 9px;
        }
      }
      &.disabled {
        &:hover {
          cursor: not-allowed;
        }
        .dropdown {
          color: ${(props) => props.theme.greyDisabled};
        }
      }
      &.multiple-select {
        min-height: 30px;
        height: auto;
        .dropdown {
          &.multiple {
            flex-wrap: wrap;
            .chip-option {
              background: ${(props) => props.theme.jadeGreen};
              color: white;
              min-width: 80px;
              height: 20px;
              border-radius: 5px;
              display: flex;
              margin: 4px;
              position: relative;
              &:hover {
                background: ${(props) => props.theme.jadeGreenHover};
              }
              .chip-text {
                font-size: 12px;
                color: ${(props) => props.theme.cardTitle};
                padding-left: 25px;
                padding-right: 16px;
                width: 100px;
              }
              .chip-clear {
                position: absolute;
                width: 8px;
                height: 8px;
                right: 8px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }
        .clear-btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          div {
            width: 13px;
            height: 13px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .option-wrapper {
      position: absolute;
      right: 0px;
      top: 36px;
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      background: white;
      display: none;
      z-index: 1;
      max-height: 180px;
      overflow-y: auto;
      &.open {
        display: block;
      }
      .option-child {
        padding: 10px 10px;
        font-size: 14px;
        list-style: none;
        color: ${(props) => props.theme.darkBackground};
        max-width: 100%;
        &:hover {
          cursor: pointer;
          color: white;
          background: ${(props) => props.theme.jadeGreen};
        }
      }
      &.option-for-graph {
        .legend-option {
          display: flex;
          align-items: center;
          max-height: 42px;
          padding: 10px 10px;
          font-size: 13px;
          list-style: none;
          color: ${(props) => props.theme.darkBackground};
          &:hover {
            cursor: pointer;
          }
          .circle-color {
            padding: 0;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }
          .data-legend-value {
            font-family: 'Prompt', sans-serif;
            font-size: 13px;
          }
          &.selected {
            background: #f2f2f2;
            opacity: 0.5;
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
`
